.App {
  /*text-align: center;*/
  /*padding-left: calc(100vw - 100%);*/
}

.site-calendar-demo-card {
  width: 500px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
